#faa-header {
    background-color: #fff;
}

#header-container {
    display: flex;
    justify-content: space-between;
}
#header-container .lm-Logo {
    min-width: 144px;
}
#faa-top-footer .lm-Row.lm-Row--main.lm-Row--start.lm-Row--stretch {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 0;
}

@media only screen and (max-width: 750px) {
    #header-container {
        padding: 15px;
    }
  }
  
  .squeeze-header {
    padding: 0 64px;
    width: 1480px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .squeeze-header a:first-child{
    margin-left:0px !important;
  }
  
  .squeeze-header a:last-child{
    margin-right:0px !important;
  }