#agent-card-image-desc-wrapper, #agency-card-grid-row, #agent-card-see-profile {
  width: 100%;
}

#agent-card-image {
  background-color: white;
  max-width: 90px !important;
  object-fit: contain;
  margin-right: 24px;
}

.contact-link-icon {
  margin-right: 8px;
}

.agent-card {
  margin: 8px;
  width: auto;
  margin-left: 0px;
  margin-right: 0px;
  padding: 24px;
}

.agent-card-profile-pic {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
.profile-pic-container {
  height: 100px !important;
  width: 100px !important;
  margin: 0;
  margin-top: 8px;
  margin-right: 16px;
  text-align: center;
  vertical-align: middle;
  background-color: #E6E6E6;
}

.agent-card .lm-Card-children {
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.agent-card h4 {
  font-size: 1.125em;
  margin-top: 0px;
  white-space: break-spaces;
  line-height: 1.6em;
}

.agent-card h5 {
  margin-top: 1rem;
  margin-block-start: 0;
  margin-block-end: 0;
  white-space: break-spaces;
}
.agent-card h6 {
  margin-top: 0px;
  /* margin-block-start: 0;
  margin-block-end: 0; */
  white-space: break-spaces;
}

.agent-card .lm-Icon-Agent {
  padding: 15px;
  border: 1px solid #343741;
  width: 90px;
  height: 90px;
}

.agent-card-top-row {
  display: flex;
  align-items:flex-start;
}

.agent-card-bottom-row>div {
  margin-top: 1rem;
}

.closed-summary {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.agent-card button, .review-card button {
  padding-top: 1rem;
  font-size: 12px;
  margin-left:auto;
  display:flex;
  align-items:center;
  text-decoration:none !important;
}
.agent-card button svg, .review-card button svg {
  width:10px;
  margin-left:5px;
  transition-duration: 0.2s;
  transition-property: transform;
}

.open-summary + button svg {
  transform: rotate(180deg);
}

.agent-card h4 > a {
  color: #06748c !important;
  text-decoration: none;
  margin: 0;
}

.agent-card h4 > a:hover{
  text-decoration: underline;
}

.agent-card h6 > a {
  color: #06748c !important;
  text-decoration: none;
  margin: 0;
}

.agent-card h6 > a:hover{
  text-decoration: underline;
}