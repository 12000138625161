.about-section-heading {
  margin-top: 0px;
}


@media only screen and (min-width: 400px) {
  .about-section-column h4:first-of-type {
    margin-top:0px;
  }
}

.about-section-icon {
  width: 32px;
  height: 32px;
}

.agent-about-section {
  display:flex;
  margin-bottom: 3rem;
}

.agent-about-section > div {
  width:50%;
}

.agent-about-section > div:first-child {
  padding-right:20px
}

@media only screen and (max-width: 400px) {
  .agent-about-section {
    flex-wrap:wrap;
    margin-bottom:0px;
  }

  .agent-about-section > div {
    width:100%;
  }

}