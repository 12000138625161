.faa-breadcrumb {
  margin: 0px;
  display:flex;
  align-items: flex-start;
  flex-wrap:wrap;
  max-width: 100%;
}

.faa-breadcrumb p {
  margin: 0;
  color: rgb(119 122 124);
}
.faa-breadcrumb svg {
  margin-left: 8px;
    margin-right: 8px;
    width: 12px;
    height: 12px;
}
.search-results-link {
  white-space: nowrap;
}

.faa-breadcrumb a {
  color: #06748c !important;
  text-decoration: none;
  margin: 0;
}

.faa-breadcrumb a:hover{
  text-decoration: underline;
}