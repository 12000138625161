#about-section-carousel-item {
  background-color: white;
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.about-section-heading {
  color: #1a1446;
}

.about-section-icon {
  width: 32px;
  height: 32px;
}

.agency-product {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 240px;
}

#about-section-products {
  display: flex;
  flex-wrap: wrap;
}

.product-icon-wrapper {
  background-color: #ffd000;
  width: 56px;
  height: 56px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  transform: scale(.9);
}
.about-section > div {
  margin-bottom:1rem;
}
.about-section > div > p {
  margin-bottom:0;
}
.about-us-sections {
  display: flex;
  flex-direction: column;
}
.gallery-image-container {
  height:"200px"
}

.gallery-image-placeholder {
  height:"200px";
  min-width: "200px";
  background-color:#E6E6E6;
  border: 1px solid #C0BFC0;
  border-radius: 3px;
  animation: pulse 1s infinite;
}

.gallery-image {
  height: 200px;
  margin: 0px;
  object-fit: contain;
  text-align: center;
  vertical-align: middle;
  background-color: #E6E6E6;
}

@keyframes pulse {
  0% {
    background-color:  rgba(230, 230, 230, .6);
  }
  50% {
    background-color:  rgba(230, 230, 230, .8);
  }
  100% {
    background-color:  rgba(230, 230, 230, .6);
  }
}

.gallery-image:hover {
  cursor: pointer;
}

.photo-gallery-modal .lm-Modal-wrapper {
  max-width: max-content !important;
  padding-left: 0px;
  padding-right: 0px;
}

.photo-gallery-modal .lm-Modal-body {
  display: flex;
  flex-direction: column;
}

.photo-gallery-modal .lm-Modal-body button {
  margin: auto;
}

.photo-indicators {
  display: flex;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 0px;
  padding-left: 0px;
}

.photo-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #1A1446;
  border-radius: 10px;
}

.photo-indicators li.active {
  background-color: #1A1446;
}

.photo-gallery-modal-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-gallery-modal-image img {
  max-width: calc(100% - 128px);
}

.about-section-row {
  display: flex;
  justify-content: space-between;
}

#about-section-carriers {
  display: flex;
  flex-wrap: wrap;
}

.affiliations {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.about-us-list {
  display: flex;
  flex-direction: column;
}

.about-section-row>div {
  width: 50%;
  padding-right: 20px;
}

.about-section-row>div:only-child {
  width: 100%;
}

#about-section-carriers div {
  width: 250px;
}

#agency-info-details-wrapper:not(:first-child) {
  padding-left:25px;
  padding-right:25px;
}