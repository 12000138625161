#maps-and-hours > div:first-child h4, #maps-and-hours h3 {
  margin-top:0px;
}
#maps-and-hours-map {
  height: auto;
  width: 100%;
  max-width: 326px;
}
.maps-and-hours-container {
  margin-bottom:20px;
}
.maps-container {
  height: 324px !important;
  width: 326px !important;
  text-align: center;
  vertical-align: middle;
}
.maps-placeholder {
  height: 324px !important;
  width: 326px !important;
  background-color:#E6E6E6;
  border: 1px solid #C0BFC0;
  border-radius: 3px;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    background-color:  rgba(230, 230, 230, .6);
  }
  50% {
    background-color:  rgba(230, 230, 230, .8);
  }
  100% {
    background-color:  rgba(230, 230, 230, .6);
  }
}

.maps-img {
  padding: 0;
  margin: 0;
  display: block;
}

.maps-and-hours-heading {
  color: #1a1446;
}

.agency-banner-contact-label.closed {
  margin-left:112px;
}

.agency-banner-contact-label.open {
  margin-left:132px;
}

#maps-and-hours-directions {
  margin-top:15px;
  margin-bottom:5px;
  font-weight:bold;
}
#maps-and-hours-directions svg {
  margin-right:5px;
}

.office-name {
  text-decoration:underline;
  text-decoration: underline;
  margin-bottom: 3px;
}