#slide-panel-icon {
    width: 40%
}

.slide-panel-button {
    margin: 20px 0px 40px 0px;
}

.slide-panel-info-card-wrapper {
    background-color: #F5F5F5;
}

.lmig-Accordion-list-listItem-header-button {
    padding:18px;
}