#agency-card-wrapper {
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: 4%;
  white-space: normal;
  border-bottom: 1px solid rgb(235, 235, 235);
}

#agency-card-wrapper a {
  white-space: normal;
}

#agency-card-wrapper:hover {
  background-color: #f6f6f6;
}

#agency-card-elite-agency {
  font-size: 15px;
  text-align: right;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

#agency-card-elite-agency span {
  margin-left: 4px;
  margin-top: 3px;
}

#agency-card-image-info-wrapper {
  display: flex;
  flex-wrap: wrap;
}

#agency-card-wrapper #agency-card-image-info-wrapper .agency-card-image {
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 3px 1px -2px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 14%);
  margin-bottom: 15px;
  width:130px;
}

#agency-card-ratings-wrapper {
  color: #343741;
  padding: 1rem 0 1rem 0;
}

#agency-card-agency-info h4 {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

#agency-card-agency-info h5 {
  margin-top: 1rem;
  margin-bottom: 0;
}

#agency-card-contact-wrapper {
  padding-top: 10px;
}

.agency-card-link {
  text-decoration: none;
  color: #06748c !important;
  margin: 0px;
}

.agency-card-link:hover {
  text-decoration: underline;
}

.agency-title-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#agency-card-agency-info {
  display: flex;
  justify-content: space-between;
}

.agency-card-contact-info-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.agency-card-contact-info-row .image-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;

}

.agency-card-phone-number {
  white-space: nowrap;
}

.agency-card-phone-number svg {
  margin-right: 8px;
}

.agency-card-website>a {
  display: flex;
  align-items: center;
}

.agency-card-website svg {
  margin-right: 8px;
  margin-bottom: 3px;
}

.agency-card-contact-info {
  padding-left: 1.25rem;
}

.agency-card-address {
  height: 100%;
  min-width: 175px;
  display: flex;
  flex-direction: column;
}

.agency-card-address #elite-image {
  background: #1a1446;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 175px;
  border-radius: 3px;
}

.agency-card-address img {
  height: 21px;
}

.agency-card-marker-column {
  height: 50px;
  margin-top: auto;
}

.agency-card-map-pin {
  position: relative;
  font-size: 0.8em;
  height: 30px;
}

.agency-card-map-pin:after {
  position: absolute;
  left: 11px;
}

#agency-card-wrapper:hover .agency-card-map-pin path {
  fill: rgb(6, 116, 140);
}

#agency-card-wrapper:hover .agency-card-map-pin {
  color: white;
}

#agency-card-wrapper:nth-child(1) .agency-card-map-pin:after {
  content: "A";
}

#agency-card-wrapper:nth-child(2) .agency-card-map-pin:after {
  content: "B";
}

#agency-card-wrapper:nth-child(3) .agency-card-map-pin:after {
  content: "C";
}

#agency-card-wrapper:nth-child(4) .agency-card-map-pin:after {
  content: "D";
}

#agency-card-wrapper:nth-child(5) .agency-card-map-pin:after {
  content: "E";
}

#agency-card-wrapper:nth-child(6) .agency-card-map-pin:after {
  content: "F";
}

#agency-card-wrapper:nth-child(7) .agency-card-map-pin:after {
  content: "G";
}

#agency-card-wrapper:nth-child(8) .agency-card-map-pin:after {
  content: "H";
}

#agency-card-wrapper:nth-child(9) .agency-card-map-pin:after {
  left: 14px !important;
  content: "I";
}

#agency-card-wrapper:nth-child(10) .agency-card-map-pin:after {
  content: "J";
}

.agency-card-distance {
  font-size: 0.75em;
  white-space: nowrap;
}

.google-reviews {
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
}

:root {
  --star-size: 16px;
  --star-color: #d5d5d5;
  --star-background: #ffcf00;
  --rating: 0;
}

.rating {
  display: flex;
  align-items: center;
}

.rating:after {
  color: #ffcf00;
  font-size: 24px;
  display: inline;
  align-items: center;
  line-height: 0;
}

.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  line-height: 1;
}

.stars::after {
  content: "★★★★★";
  letter-spacing: 1px;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.agency-card-social-column {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
  margin-top: auto;
}

.google-reviews svg {
  margin-right: 5px;
  font-size: 16px;
  color: #343741
}

.google-reviews svg {
  margin-bottom: 3px;
}

.google-reviews a, .google-reviews button {
  font-size: 14px;
  text-decoration: none;
  margin-left: 8px;
  white-space: nowrap;
}

.agency-card-bottom-row {
  margin-top: 1rem;
  display: flex;
  align-content: center;
  flex-direction: row;
}

.agency-card-bottom-row iframe {
  margin-top: auto;
  margin-bottom: auto;
}

.reviews-modal .lm-Modal-wrapper {
  max-width: 800px !important;
}

.reviews-modal .lm-Modal-body {
  /*show shadow on scroll*/
  overflow-y: scroll;
  max-height: 50vh;
  background:
    /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.reviews-modal .lm-Card {
  background: transparent;
}

.reviews-modal-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reviews-title-row-rating {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 3rem;
}

.reviews-modal-title-row small {
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
}

.agency-card-address-link {
  padding-bottom: 7px;
  padding-top: 5px;
}
.modal-page-reviews {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}
.modal-page-reviews small {
  margin-right:auto;
}
.modal-page-reviews > div {
  display:flex;
  margin-right:15px;
}
.modal-page-reviews > a {
  margin-right:55px;
}
.reviews-modal .lm-Modal-header {
  margin-bottom:0 !important;
}
.agent-name {
  text-transform: capitalize;
  margin-left:1rem;
}

.agent-icon .lm-Icon-label {
  text-transform: capitalize;
}

.agent-name-row {
  display:flex;
  align-items:center
}

.agent-name-row > a {
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 1px solid #e2e2e2;
}

.agent-name-row > a > button:hover::after {
  border-color: #1A1446;
  transform: scale(.95);
  background-color: transparent;
}

@media only screen and (max-width: 400px) {
  .agent-name-row  {
    flex-wrap:wrap;
  }
  .agent-name-row > a {
    margin-left: 0px;
    padding-left: 0px;
    border-left: 0;
    margin-top: 1rem;
  }
}