.agency-info-modal-contact {
  height: auto;
}
#agency-info-modal-contact-wrapper {
  display: flex;
  align-items: center;
}
.agency-info-modal-icon-text-wrapper {
  display: flex;
  white-space: nowrap;
}
#agency-info-modal-top-wrapper {
  display: flex;
  margin-top: 10px;
  margin-bottom:20px;
  font-size:14px;
  line-height:1.5;
}
#agency-info-modal-agency-image {
  width: auto;
  height: auto;
  align-self: flex-start;
}
.agency-info-modal-padding {
  width: 20px;
}
.agency-info-modal-padding-2 {
  width: 5px;
}
.agency-info-modal-subcategory {
  flex: 1 1 0;
  margin-top:15px;
}
#agency-info-modal-agency-name {
  
  font-size: 20px;
  outline:none;
  text-decoration:none;
  padding-right: 20px;
}
#agency-info-modal-agency-name:hover {
  text-decoration:underline;
}
#agency-info-modal-subcategories-wrapper {
  display: flex;
}

/*hide close button on agency map modal */
.gm-style-iw-a button[title="Close"] img { display: none !important}
.gm-style-iw-a button[title="Close"] {
  content: url("data:image/svg+xml,%3Csvg class='lm-Icon lm-Icon-Close' aria-label='IconClose' width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' focusable='false' role='img' label='Close'%3E%3Cpath d='M15.942 14.935L1.079.072.018 1.133l14.864 14.863L.018 30.86l1.061 1.06 14.863-14.864L30.806 31.92l1.06-1.06-14.863-14.864L31.866 1.133 30.806.072z'%3E%3C/path%3E%3Crect width='100%25' height='100%25' style='opacity:0'%3E%3Ctitle%3EIconClose%3C/title%3E%3C/rect%3E%3C/svg%3E");
  width: 16px !important;
    height: 16px !important;
    top: 15px !important;
    right: 15px !important;
    display: flex !important;
} 
.gm-style-iw-a button[title="Close"]:after {
  content:"";
  background: #f5f5f5;
  width:32px;
  height:32px;
  display:inline-block;
} 
.agency-info-modal {
  padding:10px;
}

.agency-info-modal-header {
  font-weight:bold;
}
