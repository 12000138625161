#faa-top-footer {
  background-color: #fff;
  border-top: 0.625rem solid #fed000;
  padding-top: 0;
  margin-top: 3em;
}

#top-footer {
  font-size: .875rem;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#top-footer-column-1 {
  width: 30%;
}

#top-footer-column-2 {
  width: 30%;
}

#top-footer-column-3 {
  width: 30%;
}

#top-footer-horizontal-line {
  color: #C0BFC0;
  height: 1px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

a {
  margin: 0.2rem;
  text-decoration: none;
  color: #06748c;
}

#top-footer a {
  text-decoration: none;
  color: #06748c;
  font-size: 16px;
}

#top-footer-2 {
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  font-size: .875rem;
}

#top-footer-2 a.lm-LinkInline {
  margin-right: 5px;
  
}

#top-footer-2 .top-footer-divider {
  margin-right: 5px;
  color: #343741;
}

#top-footer-2 a {
  text-decoration: none;
  color: #06748C;
  font-size: 16px;
}

#links-subgroup-container {
  padding: 3%;
}

#links-subgroup-container:first-child {
  padding-left:0px
}

#links-subgroup-container:last-child {
  padding-left:0px
}