#dropdown-container {
    display: grid;
    grid-template-columns: 70% 30%;
    width: 100%;
    align-self: center;
    border: 1px solid #C0BFC0;
    box-shadow: 0.3125rem 0.375rem 1.125rem 0.375rem rgb(0 0 0 / 9%);
    border-radius:0.1875rem;
    z-index: 3;
    position: absolute;
    top: 100px;
    background-color: white;
    animation: growDown 150ms ease-in forwards;
    max-width: 68rem;
    right:2rem;
    margin-left:2rem;
}

@keyframes growDown {
    0% {
        transform: scaleY(0), translateY(-100px);
        opacity: 0;
    }
    100% {
        transform: scaleY(1), translateY(100px);
        opacity: 1;
    }
}

#dropdown-left-side-container{
    padding: 3%;
}

#dropdown-upper-left-container{
    padding: 1% 5%
}

#dropdown-all-links-container{
    display: flex;
    margin-bottom: 4%;
    flex-wrap: wrap;
}

#dropdown-link-subgroup-container{
    flex-grow:1
}

#dropdown-line-divider{
    background-color: #E6E6E6;
    height: 1px;
    border: 0
}

#dropdown-bottom-button{
    margin-top: 4%;
    margin-left: 5%;
    margin-bottom: 3%
}

#dropdown-right-side-container{
    background-color: #F5F5F5;
    padding:10%;
}