#footer-outer-container {
    display: flex;
    padding: 0 2rem 2rem 2rem;
}
#footer-outer-container p:first-of-type {
    margin-top:0px;
}
#footer-icons {
    display: flex;
}

.footer-text {
    color: white;
    font-size: 0.75rem;
    margin-top: 1rem;
    line-height: 18px;
}

#footer-safeco-logo {
    width: 12rem;
    margin-right: 25%;
}

#footer-icon-wrapper a:first-child {
    margin-top: 0px;
}

#footer-icon-wrapper {
    border-left: 0.15rem solid #fed000;
    display: flex;
    flex-direction: column;
    padding: 0px 10% 0px 10%;
}

#footer-icon-facebook {
    width: 30.72px;
    height: 30.72px;
    margin-bottom: 15%;
}

#footer-icon-facebook svg {
    margin-top: 0px
}

#footer-icon-linkedin {
    width: 30.72px;
    height: 30.72px;
    margin-top: 10%;
    margin-bottom: 10%;
}

#footer-icon-twitter {
    width: 30.72px;
    height: 30.72px;
    margin-top: 10%;
    margin-bottom: 10%;
}

#faa-bottom-footer {
    background-color: #1A1446;
}


@media only screen and (max-width: 400px) {

    /*mobile only*/
    #footer-outer-container {
        flex-wrap: wrap;
    }

}