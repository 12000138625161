#agency-info-page-banner {
  background-color: #1a1446;
  height: 363px;
}

#agency-info-page-icon {
  margin-right: 5px;
}

.agent-cards-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.agent-cards-heading .lm-Caption {
  margin-bottom: 10px;
}

.content-container {
  min-height: 150rem;
}

.placeholder-card {
  margin: 8px;
  margin-left: 0px;
  margin-right: 0px;
  height: 12rem;
  background-color: rgba(230, 230, 230, 1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    background-color:  rgba(230, 230, 230, .6);
  }
  50% {
    background-color:  rgba(230, 230, 230, .8);
  }
  100% {
    background-color:  rgba(230, 230, 230, .6);
  }
}

.update-profile-link {
  margin-left: calc(25% + 25px);
  margin-bottom: 15px;
}

.agency-page-alert {
  display: flex;
  margin: 3rem;
  justify-content: center;
}

.reviews-heading h3 {
  margin-top: 1em !important;
}

.reviews-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.reviews-title-row h3 {
  margin-bottom: 0px;
}

.reviews-title-row .reviews-title-row-rating {
  margin-right: 0px;
}

.agency-page-reviews {
  text-align: right;
}
.agency-page-reviews .rating:after {
  font-size:36px;
}

.agency-page-reviews .rating {
  font-size: 24px;
}

.agency-page-reviews > div {
  display:flex;
}
