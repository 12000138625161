#header-nav-bar {
    display: flex;
    align-self: center;
    justify-content: flex-end;
    width: 100%;
    height: 109px;
}

#header-nav-bar>a:first-child {
    width: 38.4vw;
    min-width: 144px;
    max-width: 224px;
    margin: auto auto auto 1.5rem;
}

#header-nav-bar a:last-child {
    margin-right: 1.5rem;
}

#header-login {
    white-space: nowrap;
}

#header-login-wrapper {
    align-self: center
}

button.header-button {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-self: center;
    background-color: white;
    border: none;
    letter-spacing: .4px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 16px;
    font-family: Roboto, Arial, sans-serif;
    margin-right: 6rem;
    color:#343741;
}

.header-button * {
    white-space: pre-wrap;
}

.header-button:active, .header-active-tab {
    color: black;
    outline: 1px solid #000;
    outline-offset: 0.5rem;
}

.header-active-tab::after {
    top: 85px;
    content: url("../imgs/caret.svg");
    position: absolute;
    z-index: 5;
    animation: fadeIn 1s;
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media only screen and (max-width: 1100px) {

    button.header-button {
        margin-right: 3rem;
    }

}