#landing-heading-1 {
    color: #002663;
    margin-top: 2rem;
    margin-bottom: 0.1rem;
}

#landing-heading-2 {
    color: #002663;
    margin-top: 0.5rem;
}

#landing-content-1 {
    color: black;
    font-size: 14px;
    padding-right: 3rem;
    padding-left: 0rem;
    max-width: 1000px;
}

#landing-content-2 {
    color: black;
    font-size: 14px;
    padding-right: 3rem;
    padding-left: 0rem;
    margin-bottom: 3%;
    max-width: 1000px;
}

#landing-page {
    background-color: #fff;
    border-top: 0.3rem solid #fed000;
    padding: 0 1.5rem;
}