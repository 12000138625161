#slide-panel-wrapper {
    background: rgb(255, 255, 255);
    position: fixed;
    top: 0px;
    bottom: 0px;
    width: 100%;
    max-width: 480px;
    overflow-y: scroll;
    right: 0px;
    z-index: 11;
    animation: slideLeft 300ms ease-out;
}

#background {
    background-color: black;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    position: fixed;
    opacity: 0.8
}

@keyframes slideLeft {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
#slide-panel-logo-x-wrapper {
    display: flex;
    padding-left: 15px;
    padding-top: 2%;
    width: 98%;
    justify-content: space-between;
    right: 1%;
}