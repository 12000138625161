#agency-banner-contact-info-wrapper {
  display: flex;
  white-space: nowrap;
}

#agency-banner-img-and-contact {
  display: flex;
  justify-content: space-between;
}

.address-section {
  display: flex;
}
.address-section svg {
  margin-top:2px;
}

.page-header-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.contact-info-top-row {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.contact-section-divider {
  border-top: 1px solid #d3d3d3;
  margin-top: 7px;
  padding-top: 7px;
}
.small-contact-section {
  font-size: 14px;
}
.contact-info-top-row>div {
  padding-right: 2%;
}

#agency-banner-buttons-wrapper {
  position: absolute;
  width: 100%;
}

.agency-banner-icon-text-wrapper {
  display: flex;
  align-items: center;
  margin-right: 5%;
}

#agency-banner-agency-image {
  margin-right: 25px;
  width: 100px;
  height: 100%;
  max-height: 100px;
}

.agency-banner-top-row, .agency-banner-name-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.agency-banner-button {
  white-space: nowrap;
}

.agency-banner-button-section {
  margin-left: auto;
  margin-top: 15px;
  align-self: flex-end;
  margin-bottom: 8px;
}

.agency-banner-contact-label {
  margin-top: 25px;
  text-decoration: underline;
  padding-bottom: 5px;
}

.agency-banner-top-row {
  border-bottom: 1px solid #00000070;
  padding-bottom: 15px;
  padding-top: 15px;
}

.agency-banner-name-row h1 {
  margin-top: 0em;
  margin-bottom: 0em;
}

.agency-banner-name-row h1+h1 {
  padding-left: 25px;
  margin-left: 25px;
  border-left: 1px solid;
}

.eliteStatus {
  min-width: 175px;
}

.eliteStatus svg {
  margin-right: 5px;
}

.page-header-name {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  justify-content: center;
}

.page-header-name h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 1000px;
}

#agency-info-details-wrapper h3, .agent-cards-heading h3 {
  margin-top: 0px;
}

.social-media-icons {
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  margin-left: -16px;
}

.social-media-icons a:only-child {
  margin-top: 31px;
}

.social-media-icons a:not(:first-child) {
  margin-top: -16px;
  margin-right: auto;
}

.social-media-icons a:hover {
  z-index: 200;
}

/*business hours*/

.business-hours {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  line-height: 1.2;
  font-weight: 300;
  width: 100%;
}

.business-hours>div, .business-hours>i {
  display: flex;
  justify-content: flex-end;
}

.business-hours span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.today {
  font-weight: bold;
}

.business-hours-container .lmig-Badge {
  margin-right: 20px;
}

.maps-and-hours-day-of-week {
  text-transform: capitalize;
}

.day-of-week-row {
  display: flex;
  justify-content: space-between;
  width: 215px;
}

.business-hours i {
  font-size: 14px;
  margin-top: 15px;
}

.business-hours-content {
  display: flex;
}

.agency-image-name {
  display: flex;
}

.agency-banner-icon-label svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.small-header {
  font-size: 14px;
  margin-right: 5px
}

.facebook-icon-and-like-plugin {
  display: flex;
  align-items: center;
}

.facebook-icon-and-like-plugin iframe {
  max-height: 20px;
}

#agency-banner-phone svg {
  width: 18px;
  height: 18px;
}
.contact-info-col:empty {
  display:none
}
@media only screen and (max-width: 881px) {
  #agency-banner-img-and-contact {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 400px) {

  /*mobile only*/
  .agency-banner-button-section {
    width: 100%;
  }

  h3 {
    margin-top: 1em !important;
  }

  .about-section, #agency-info-details-wrapper h3 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .page-header-name h1 {
    font-size: 1.75rem !important;
    line-height: 1.2em !important;
    letter-spacing: normal !important;
    font-weight: 400 !important;
  }

  .contact-info-top-row {
    min-height: auto;
  }

  .agency-banner-contact-label {
    margin-left: 0px !important;
  }

  .business-hours>div, .business-hours>i {
    flex-direction: row-reverse;
  }

  .business-hours-container .lmig-Badge {
    margin-left: 20px;
    margin-right: 0px;
  }

  .social-media-icons {
    flex-direction:row;
    align-items:center;
    margin:auto;
  }
  .social-media-icons a {
    margin-top:0px !important;
  }
  .facebook-icon-and-like-plugin {
    flex-direction:row-reverse;
  }
}

.small-elite-badge {
  max-height:40px;
}

.eliteStatus #elite-image {
  background: #1a1446;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 175px;
  border-radius: 3px;
}

.eliteStatus #elite-image img {
  height: 21px;
}

.profile-pic-container {
  height: 100px !important;
  width: 100px !important;
  margin:20px;
}

.cls-1{fill:#00ac47;}
.cls-2{fill:#5bb974;}
.cls-3{fill:#00832d;}
.chat-text{margin-left: 5px;}