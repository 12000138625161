.review-name-row {
  display: flex;
  align-items: center;
  color: #343741 !important;
}
.review-name-row:hover .review-author-name {
  text-decoration: underline;
}
.review-name-row:hover {
  text-decoration: none;
}
.review-name-row img {
  margin-right: 1.5rem;
  display: block;
  max-width: 230px;
  max-height: 60px;
  width: auto;
  height: auto;
}

.placeholder-review {
  margin: 8px;
  margin-left: 0px;
  margin-right: 0px;
  height: 12rem;
  background-color: rgba(230, 230, 230, 1);
  animation: pulse 1s infinite;
}

.lm-Modal .review-card {
  width: 95%;
}
.review-card:last-child {
  margin-bottom:1rem;
}
.review-card {
  margin-top: 1rem;
}

.review-card-text {
  margin-top: 1rem;
}
.review-card-text:empty {
  display:none;
}
.review-star-rating {
  font-size: 24px;
}

.review-row p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px
}

.capitalize {
  text-transform: capitalize
}

.review-card .lm-Card-children {
  margin-bottom: 0px;
}

.review-top-row {
  display:flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.review-date {
  margin-left:1rem;
  white-space:nowrap;
}

@media only screen and (max-width: 400px) {
  .review-date {
    margin-left:0px;
  }
}
