#search-box-banner {
  background-color: #1a1446;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.non-sticky {
  position: relative !important;
}

#loading-spinner {
  height: 100px;
}

#search-box-row {
  width: 100%;
  align-items: flex-end;
}

#search-box-inputs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  color: white;
}

.get-browser-location {
  display: flex;
  align-items: center;
}

.get-browser-location::after {
  content: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ibG0tSWNvbiBsbS1JY29uLUxvY2F0aW9uIiBhcmlhLWxhYmVsPSJJY29uTG9jYXRpb24iIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZvY3VzYWJsZT0iZmFsc2UiIHJvbGU9ImltZyIgbGFiZWw9IkxvY2F0aW9uIj48cGF0aCBzdHJva2U9IndoaXRlIiBmaWxsPSJ3aGl0ZSIgZD0iTTE1Ljk5Ny4xNGMyLjk5NiAwIDUuODE0IDEuMTY4IDcuOTM0IDMuMjg5IDMuOSAzLjg5NyA0LjM4NiAxMS4wNDQgMS4wNTkgMTUuNjA1LTIuMTQ1IDIuOTQyLTguMTQzIDExLjQyMi05LjEzNSAxMi44MjVsLS45MS0xLjMxMmMuNzUtMS4wNiAyLjMyNi0zLjI4NiAzLjk4OC01LjYyNmwuNTU2LS43ODJhNzQyLjgxOCA3NDIuODE4IDAgMCAxIDQuMjg5LTUuOTg4YzIuODY3LTMuOTMgMi40NDMtMTAuMzE0LS45MDgtMTMuNjYxYTkuNjU2IDkuNjU2IDAgMCAwLTYuODczLTIuODUgOS42NTYgOS42NTYgMCAwIDAtNi44NzMgMi44NDdjLTMuMzI4IDMuMzI3LTMuNzU2IDkuNzA3LS45MTcgMTMuNjUzIDEuNTU5IDIuMTY3IDUuNzM3IDguMjYzIDYuNzQ1IDkuNzM1bC0uOTI4IDEuMzAyLS4wODYtLjEyN2MtLjA1Mi0uMDc2LTUuMTktNy41OS02Ljk1LTEwLjAzNEMzLjY5OCAxNC40NCA0LjE4OSA3LjMgOC4wNjQgMy40MjZBMTEuMTQ2IDExLjE0NiAwIDAgMSAxNS45OTcuMTQyem0uMDg4IDYuODdhNC4yNjcgNC4yNjcgMCAwIDEgNC4yNjUgNC4yNjIgNC4yNyA0LjI3IDAgMCAxLTQuMjY1IDQuMjY2IDQuMjcgNC4yNyAwIDAgMS00LjI2NS00LjI2NiA0LjI2NyA0LjI2NyAwIDAgMSA0LjI2NS00LjI2MXptMCAxLjVhMi43NjYgMi43NjYgMCAwIDAtMi43NjUgMi43NjIgMi43NjkgMi43NjkgMCAwIDAgMi43NjUgMi43NjYgMi43NjkgMi43NjkgMCAwIDAgMi43NjUtMi43NjYgMi43NjYgMi43NjYgMCAwIDAtMi43NjUtMi43NjF6Ij48L3BhdGg+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgc3R5bGU9Im9wYWNpdHk6MCI+PHRpdGxlPkljb25Mb2NhdGlvbjwvdGl0bGU+PC9yZWN0Pjwvc3ZnPg==");
  transform: scale(.6);
}

.search-input-group {
  margin-top: 0px;
  padding: 1.5rem;
}

div.overlay {
  width: 100%;
}

div.overlay.loaded {
  position: sticky;
  top: 285px;
  height: calc(100vh - 285px);
  width: 0;
  z-index: 1;
}

div.overlay.loaded > div {
  position: absolute;
  width: calc(100vw - 17px);
  height: calc(100vh - 285px);
  background-color: #fff;
  opacity: 0.7;
}

#search-box-results-wrapper-2 {
  display: flex;
  position: relative;
}

#search-box-agency-results {
  width: 100%;
  max-width: 650px;
  display: block;
}

#search-box-map {
  width: 100%;
  height: calc(100vh - 285px);
  position: sticky !important;
  top: 285px;
}

.hide-results #search-box-agency-results, .hide-results #search-box-map {
  height: 0px !important;
  display: none;
}

#search-box-submit-button {
  padding-top: 11px;
  padding-bottom: 11px;
  margin-left: 1rem;
  width: 100%;
  margin-bottom: 8px;
}

#search-box-results-count {
  color: white;
}

/* For google's autocomplete */
.pac-container {
  box-shadow: none !important;
  margin-left: 2px !important;
}

@media only screen and (max-width: 400px) { /*mobile only*/
  #search-results-top-row  {
    justify-content:center !important;
  }
}

@media only screen and (min-width: 769px) {
  #search-box-input-agency-col {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 768px) {


  #search-box-banner {
    position:relative;
  }
  #search-box-agency-results {
    width: 100%;
    height: auto;
    border-top: 1px solid rgba(121, 121, 121, 1);
  }

  #search-box-map {
    position:relative;
    top:auto;
    width: 100%;
    border: 1px solid rgba(121, 121, 121, 1);
  }

  #search-box-submit-button {
    max-width: 100%;
    width: 100%;
    margin: auto;
  }

  #search-box-results-wrapper-2 {
    flex-wrap: wrap-reverse
  }

  #search-box-results-wrapper-2 #search-box-map {
    width: 100%;
    height: 50vh;
  }

  #search-box-results-wrapper-2 #search-box-agency-results {
    width: 100%;
    max-width: 100%;
  }
}

.error-card {
  max-width: 960px;
  width: calc(100vw - 40px);
  margin: 20px;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.5rem !important;
}
.pagination span:last-child button.active {
  margin-right:1rem;
}
.pagination button.num {
  display: flex;
  align-items: center;
  margin: 2.5px;
  padding: 12.5px;
  background: none;
  border: none;
  color:#343741;
}
.pagination button.num:hover {
  cursor:pointer;
}
.pagination .active {
  border-radius: 100%;
  background: #f5f5f5 !important;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
}

#search-box-input-location, #search-box-input-agency {
  width: 100%;
}

.top-row-pagination .num:not(.active) {
  color: white;
}

.top-row-pagination .lm-IconButton::after {
  background: none;
}

.top-row-pagination .lm-IconButton--white:before {
  background: transparent;
}

.top-row-pagination path {
  fill: white;
}

#search-results-top-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap-reverse;
  background-color: #3b3758;
  padding: 0.8rem 1.5rem .8rem 0;
  box-shadow: #0c0539 inset 0 2px 5px 3px
}

#search-results-top-row #page-count {
  justify-content: center;
  font-size: 1rem;
  color: white;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-left: .6rem;
}

.search-box-input-field .lm-Field-actionButton {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
}

.search-box-input-field:focus-within .lm-Field-actionButton {
  display: block !important;
  cursor: pointer;
}

.cancel-filter {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 30px;
  background: #f5f5f5;
  transform: scale(.9);
}

.cancel-filter:not(h4 + .cancel-filter) {
  margin-left: -12px;
}

.cancel-filter svg {
  transform: scale(.8);
}

.cancel-filter button {
  background: #f5f5f5;
  border-radius: 30px;
}

.cancel-filter div {
  padding-left: 15px;
  padding-right: 5px;
}

.cancel-filter .lm-IconButton--white:after {
  background-color: #f5f5f5;
}

.language-filter {
  margin-top: .5rem;
  font-size: 14px;
}

.language-filter .lm-Field-select {
  border-radius: 25px;
  width: 128px;
  height: 40px;
  padding: 0;
}

.language-filter label {
  margin-left: 0.8rem;
  top: 4px !important;
}

.language-filter svg {
  width: 12px !important;
  top: 14px !important;
}

/*spinner*/
.lms-CircularProgressTrack--64 {
  width: 64px;
  height: 64px;
}

@keyframes lms-circular-progress-spinner-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes spinner-dash--64 {
  0% {
    stroke-dashoffset: 191.64;
  }

  50% {
    stroke-dashoffset: 47.91;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 191.64;
    transform: rotate(450deg);
  }
}

.lms-CircularProgressSpinner {
  justify-content: center;
  display: flex;
  margin: 6rem 0rem;
  width:100%;
}

.loading .pagination {
  display: none;
}

#map-search-control-ui {
  background: none padding-box rgb(255, 255, 255);
  border: 0px;
  margin-top: 10px;
  padding: 0px 23px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  direction: ltr;
  overflow: hidden;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  color: rgb(86, 86, 86);
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  min-width: 68px;
}

#map-search-control-ui>#map-search-control-ui-text {
  color: rgb(25, 25, 25);
  line-height: 38px;
  padding-left: 5px;
  padding-right: 5px;
}

#map-search-control-ui-input {
  margin-left: 5px;
}

.white-text {
  color: white !important;
}

.lm-Card-children {
  margin-bottom: 0px;
}