
#mobile-nav {
    display:none;
    position: fixed;
      bottom: 0px;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      height:75px;
      background-color:#1A1446;
      color:white;
      box-shadow:0.3125rem 0.375rem 1.125rem 0.375rem rgb(0 0 0 / 20%);
  }
  
  
  @media only screen and (max-width: 768px) {
    /*mobile only*/
    #mobile-nav {
      display:flex;
    }
  }